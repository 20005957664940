@import "../../node_modules/bootstrap/scss/bootstrap";

$color_dark: var(--clr-primary-400);
$color_green: var(--clr-accent-400);
$color_white: var(--clr-neutral-100);
$color_gray: var(--clr-neutral-200);
$font-family: var(--ff-primary);

:root {
  --clr-accent-400: #2DD671;
  --clr-neutral-100: #FFFFFF;
  --clr-neutral-200: #F5F5F5;
  --clr-primary-400: #393939;
  --ff-primary: 'DM Sans', sans-serif;
  --fs-small: 0.875rem;
  --fs-normal: 1rem;
  --fs-medium: 1.5rem;
  --fs-large: 2rem;
  --fs-xlarge: 2.5rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font: inherit;

  &::before {
    box-sizing: border-box;
  }

  &::after {
    box-sizing: border-box;
  }
}

ul[role='list'] {
  list-style: none;
}

ol[role='list'] {
  list-style: none;
}

html {
  &:focus-within {
    scroll-behavior: smooth;
  }

  height: 100%;
}

body {
  height: 100%;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-size: var(--fs-normal);
  font-family: $font-family;
  color: $color_dark;
}

.container {
  max-width: 1140px;
  padding: 0 1.3rem;
  margin-inline: auto;
}

.block-flow {
  > * + * {
    margin-top: 1em;
  }
}

.block-flow-high {
  > * + * {
    margin-top: 2em;
  }
}

h1 {
  line-height: 1.4;
  letter-spacing: .1rem;
}

p {
  margin: 0 !important;
}

p[data-width="xnarrow"] {
  max-width: 14ch;
}

p[data-width="narrow"] {
  max-width: 32ch;
}

p[data-width="wide"] {
  max-width: 100%;
}

button {
  color: $color_dark;
}

a {
  color: $color_dark !important;
  font-size: inherit;

  &:not([class]) {
    text-decoration-skip-ink: auto;
  }
}

ol, ul {
  margin: 0 !important;
  padding-left: 0;
}

img {
  max-width: 100%;
  display: block;
}

picture {
  max-width: 100%;
  display: block;
}

.logo {
  max-width: 12rem;
  @media (max-width: 50em) {
    width: 100%;
  }
}

svg {
  max-width: 100%;
  display: block;
}

.bg-dark {
  background-color: $color_dark !important;
}

.bg-green {
  background-color: $color_green !important;
}

.text-green {
  color: $color_green !important;
}

.bg-white {
  background-color: $color_white;
}

.bg-gray {
  background-color: $color_gray;
}

.bg-gray-desktop {
  background-color: $color_gray;
  @media (max-width: 50em) {
    background-color: $color_white;
  }

}

.nav-link {
  white-space: nowrap;
  &:hover {
    color: $color_green !important;
  }
}

//BUTTONS

button {
  border-radius: 6.25rem;
  border: 1px solid $color_green;
  font-size: var(--fs-normal);
  background-color: $color_green;

  a {
    text-decoration: none;
    font-size: var(--fs-normal);
    color: $color_dark;
  }
}

//HERO HEADER

.hero-header {

  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: calc(-50vw);

  @media (max-width: 50em) {
    video {
      width: 100%;
    }
  }
  @media (min-width: 991px) and (max-width: 1200px) {
    margin-top: 6rem;
  }

  .button-primary {
    margin: 1rem 0;
  }
}

//TABLE

table {
  border: 8px solid $color_gray;
  border-collapse: collapse;
  margin: 0 auto;
  padding: 0;
  table-layout: fixed;
  min-width: 100%;
  font-weight: 500;

  img {
    margin: 0 auto;
    @media (max-width: 50em) {
      max-width: 1.2rem;
    }
  }
}

small {
  img {
    margin: 0 auto;
    @media (max-width: 50em) {
      max-width: 1.2rem;
    }
  }
}

tbody
tr:first-child {
  background-color: $color_gray;
}

tr:first-child
td:first-child {
  border-top-right-radius: 50px;
  background-color: $color_white;
}

table th {
  text-align: center;
  border: 8px solid $color_gray;
  color: $color_dark;
  font-weight: bold;
  padding: .5rem;
}

table td {
  border: 8px solid $color_gray;
  padding: 1rem;
  @media (max-width: 50em) {
    padding: .7rem;
    font-size: .875rem;
    line-height: 1.3;
  }

  &:first-child {
    min-width: 15rem;

    button {
      display: inline-flex;
      gap: .5rem;
    }
  }
}

table tr {
  text-align: left;
  border: 8px solid $color_gray;
}

.accordion-item {
  border: none !important;
  border-bottom: 1px solid $color_gray !important;
  background-color: $color_dark !important;
}

.accordion {
  background-color: $color_dark !important;
  color: $color_white !important;
  --bs-accordion-bg: $color_dark !important;
  --bs-accordion-border-radius: none !important;
}

.accordion-button:not(.collapsed) {
  background-color: $color_dark !important;
  box-shadow: none !important;
  color: $color_white !important;
}

.accordion-button::after {
  --bs-accordion-btn-icon: url("../images/others/arrow_down_white.svg") !important;

}

.accordion-button:not(.collapsed)::after {
  --bs-accordion-btn-active-icon: url("../images/others/arrow_down_white.svg") !important;

}

.accordion-button {
  color: $color_white !important;
}

.accordion-button:focus {
  border: none !important;
  box-shadow: none !important;
}

//FOOTER

.footer-logo {
  @media (min-width: 50em) {
    width: 22rem;
  }
}


.border-left {
  position: relative;
  content: "";
  background-image: url("../images/others/curved_green_line.svg");
  min-height: 20rem;
  padding: 3.3rem 0 2.75rem 2.325rem;
  z-index: 999;
  background-repeat: no-repeat;

  li {
    &:nth-child(2) {
      margin-bottom: 2.75rem;

      &:after {
        content: "";
        display: block;
        border-bottom: 4px solid $color_green;
        position: absolute;
        width: 21rem;
        z-index: 999;
      }
    }
  }
}

.invest-column {
  &:nth-child(2) {
    text-align: right;
  }

  @media(max-width: 50em) {
    display: none !important;
  }
}

.flex-item {
  @media (min-width: 50em) {
    max-width: 19.375rem;
    line-height: 1.5;
  }

  img {
    width: 50%;
    margin: 0 auto;

  }

  @media (max-width: 50em) {
    //flex-direction: row-reverse;
    //text-align: left;
    //max-width: 100%;

    img {
      width: 35%;
      margin-left: 0.2rem;
      order: 2;
    }
  }
}

.text-container {
  overflow: hidden;
}


input {
  border: none;
  min-width: 18rem;
  border-radius: 1rem !important;

  &:focus-visible {
    outline: none;
  }

  @media (max-width: 50em) {
    width: 100%;
  }
}

label {
  @media (max-width: 50em) {
    width: 100%;
  }
}

// PAGE ANIMATION
.animate-hidden {
  opacity: 0;
  transform: translateY(0);
  transition: all 2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@media (prefers-reduced-motion: no-preference) {
  .animate-hidden {
    transition: all 0.8s;
  }
}

.show {
  opacity: 1;
}

.tooltip-button {
  all: unset;
}

.image-mobile {
  margin-right: -1.5rem;
  margin-top: -5rem;
}

.mobile-image-small {
  @media (max-width: 50em) {
    max-width: 1.2rem;
  }
}

.ebook-image {
  @media (max-width: 50em) {
    width: 60%;
    margin: 0 auto;
  }
}

.faq {
  background-color: var(--clr-primary-400);
  color: $color_white;
  @media (max-width: 50em) {
    background-color: var(--clr-neutral-200);
    color: $color_dark;
  }
}

.text-bg-left {
  display: flex;
  @media (min-width: 50em) {
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    top: 0;
    right: 50%;
    margin-right: -1rem;
    z-index: -1000;
  }
}

.text-bg-right {
  display: flex;
  @media (min-width: 50em) {
    display: flex;
    align-items: center;
    position: relative;
  }
}
.text-bg-right::after {
  @media (min-width: 50em) {
    content: '';
    position: absolute;
    background: linear-gradient(90deg, #FFF 38.67%, #F3F3F3 100%);
    z-index: -1000;
    width: 100vh;
    height: 25rem;
    left: 0;
  }
}

.question-mark {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
}

.mobile-width-20 {
  @media (max-width: 50em) {
    width: 20%;
  }
}

//FORM
.form-control:focus {
  border: none;
  box-shadow: none;
}

.carousel-control-prev,
.carousel-control-next {
  top: -3rem;
}

.carousel-control-next-icon {
  background-image: url("../images/slider/arrow_green_right.svg");
  @media (max-width: 991px) {
    position: absolute;
    bottom: 20.5rem;
  }
}

@media (max-width: 767px) {
  .carousel-inner {
    height: auto !important;
  }
}

.carousel-control-prev-icon {
  background-image: url("../images/slider/arrow_green_left.svg");
  @media (max-width: 991px) {
    position: absolute;
    bottom: 20.5rem;
  }
}

.carousel-control-next-icon-dark {
  background-image: url("../images/slider/arrow_right.svg");
  width: 14px;
  height: 28px;
}

.carousel-control-prev-icon-dark {
  background-image: url("../images/slider/arrow_left.svg");
  width: 14px;
  height: 28px;
}

.carousel-item {
  min-height: 14rem;

  img {
    width: 10rem;
    @media (max-width: 991px) {
      width: 6rem;
      height: 5rem;
    }
  }
}

.slider-image {
  position: absolute;
  top: -100px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 1000;
}

.two {
  background-color: $color_white;
  border-radius: .6rem;
  position: relative;
  top: -1.5rem;
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  @media (min-width: 991px) {
    display: flex;
    gap: 5rem;
    padding: 1rem 10rem;
    justify-content: center;
    align-items: center;
  }
}

.modal-backdrop {
  --bs-backdrop-bg: transparent !important;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  display: none;

}

.popup-content {
  background: #fff;
  padding: 1rem;
  border-radius: .8rem;
  display: flex;
  width: fit-content;
  flex-direction: column;
  margin-right: 1rem;
  margin-left: 1rem;
  min-width: 18rem;
}

.popup-button {
  opacity: 0.5;
  background-image: url("../images/others/btn-close.svg");
  width: 30px;
  background-repeat: no-repeat;
  height: 30px;
  background-color: transparent;
  border: none;
}

.text-mobile-uppercase {
  @media (max-width: 50em) {
    text-transform: uppercase;
  }
}

.long {
  min-height: 25rem;
}

.spacer {
  width: 100%;
  height: 4rem;
  @media (max-width: 50em) {
    height: 3rem;
  }
}

.accordion-button {
  font-size: 1.250rem !important;
  @media (max-width: 50em) {
    padding: 1rem 0 1rem 0 !important;
    font-size: 1.1rem !important;
  }
}

.accordion-body {
  color: $color_white !important;
  padding: 0 1rem 1rem 0 !important;
  font-size: var(--fs-small);
  @media (min-width: 50em) {
    padding: 0 5rem 1.5rem 5rem !important;
  }
}

.tooltip-hidden {
  display: none;
}

.error-message {
  color: red;
}

//LANGUAGE BUTTON
.btn-lang {
  display: flex;
  align-items: center;
  @media (min-width: 50em) {
    border: none !important;
  }
}

.btn-lang::before {
  background-image: url("../images/others/language.svg");
  height: 20px;
  content: '';
  width: 20px;
  background-repeat: no-repeat;
  display: inline-block;
}

.form-select {
  width: auto !important;
  border: none !important;
  text-align: center;
}

.form-select:focus {
  border-color: #dee2e6 !important;
  outline: 0;
  box-shadow: none !important;
}

.offcanvas-body {
  @media (max-width: 991px) {
    align-items: start !important;
  }
}
